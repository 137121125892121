<template>
  <MyHeader :title="title" back-button>
    <div class="SerchBox">
      <Search
        v-model="select.keywords"
        class="TopSearch"
        placeholder="请输入名称、款号、货号"
        theme="primary"
        @keydown.enter="selectGoodsList"
        :autofocus="autofocus && !$route.meta.isBack"
        :key="searchKey"
      >
        <template v-slot:right>
          <Button
            @click="selectGoodsList"
            class="SelectBtn"
            :log="
              JSON.stringify({
                describe: { action: '点击搜索', keyword: select.keywords },
              })
            "
            v-log
            >搜索</Button
          >
        </template>
      </Search>
    </div>
    <div class="ConditionList">
      <div
        class="ConditItem"
        :class="sortType == 'all' ? 'ActiveSort' : ''"
        @click="SortList('all')"
        v-log="{ describe: { action: '点击综合' } }"
      >
        <span class="ConditSize">综合</span>
      </div>
      <div
        class="ConditItem"
        :class="sortType == 'sales' ? 'ActiveSort' : ''"
        @click="SortList('sales')"
        v-log="{ describe: { action: '点击销量' } }"
      >
        <span class="ConditSize">销量</span>
      </div>
      <div
        class="ConditItem"
        :class="sortType == 'stock' ? 'ActiveSort' : ''"
        @click="SortList('stock')"
        :log="
          JSON.stringify({
            describe: {
              action: '点击库存',
              keyword: params.stock_order == 2 ? '降序' : '升序',
            },
          })
        "
        v-log
        v-if="is_business !== 1"
      >
        <span class="ConditSize">库存</span>
        <img :src="sortImg" v-if="params.stock_order" class="SortImg" />
      </div>
      <div
        class="ConditItem"
        :class="sortType == 'news' ? 'ActiveSort' : ''"
        @click="SortList('news')"
        :log="
          JSON.stringify({
            describe: {
              action: '点击上新',
            },
          })
        "
        v-log
      >
        <span class="ConditSize">上新</span>
      </div>
      <div class="ConditItem" @click="setFilter">
        <span class="ConditSize">筛选</span>
        <Icon
          class="Filter"
          use="filter"
          :class="FilterIn ? 'ActiveSort' : ''"
        />
      </div>
    </div>
    <NoticeBar
      v-if="isWeixinLook"
      text="微信中当前仅支持银行快捷支付,建议在钉钉中使用"
      mode="closeable"
      @close="closeWeixinTips"
    >
      <template v-slot:left-icon></template>
    </NoticeBar>
  </MyHeader>
  <List
    v-model:loading="state.loading"
    :finished="state.finished"
    :error="error"
    @load="getGoodsList"
    @update:error="getGoodsList"
    :immediate-check="false"
    class="ShopList"
    v-if="GoodListShow"
  >
    <GoodsCard
      v-for="item in GoodsList"
      :detail="item"
      :key="item.id"
      :id="item.id"
      :image="item.image"
      :keyword="params.keywords"
      :is_pre_sales="item.is_pre_sales"
      :goods_title="item.goods_title"
      :company="item.company"
      :goods_number="item.goods_number"
      :is_futures="item.is_futures"
      :price="item.price"
      :stock_amount="item.stock_amount"
    />
  </List>
  <div class="NotFoundBox" v-else>
    <img src="../../assets/image/sort_image_searchem.svg" class="NotFoundImg" />
    <div class="NotFoundFontTitle" v-if="!FilterIn && params.keywords == ''">
      {{ !FilterIn && params.keywords == '' ? '没有任何商品' : '无结果' }}
    </div>
    <div class="NotFoundFontTitle" v-if="params.keywords !== ''">
      搜索不到该商品
    </div>
    <div class="NotFoundFont" v-if="params.keywords == ''">
      {{
        !FilterIn ? '请联系您的品牌商给您开放商品' : '无结果，换个搜索词试试～'
      }}
    </div>
    <!--  <div class="NotFoundFont" v-else>您可发起客服聊天一键发送铺货请求</div>
    <div class="distribution" @click="sendDist" v-if="params.keywords !== ''">
        发送铺货请求
      </div> -->
  </div>
  <div class="Center">
    <Popup
      v-model:show="Filter"
      position="right"
      @closed="closePopup()"
      @click-overlay="closeOverLay()"
      @open="openPopup()"
      :duration="0"
    >
      <img
        src="../../assets/image/login_cha.png"
        class="CloseFilter"
        @click="setFilter"
      />
      <div class="FilterTitle">小类</div>
      <div class="FilterList">
        <div
          v-for="(item, index) in FilterTypeList"
          :key="item"
          class="FilterItem"
          :class="select.bottom_class.indexOf(item) > -1 ? 'FilterActive' : ''"
          v-show="index < 6 || FilterTypeList.Show"
          :active="select.bottom_class.indexOf(item) > -1"
          @Click="TypeSelect(item, 'bottom_class', 'mult')"
        >
          {{ item }}
        </div>
      </div>
      <div
        v-show="FilterTypeList.length > 6"
        class="OpenClassify"
        @Click="openClassify"
      >
        <img
          v-show="FilterTypeList.Show"
          src="../../assets/image/sort_icon_xiala.svg"
        />
        <img
          v-show="!FilterTypeList.Show"
          src="../../assets/image/sort_icon_zhankai.svg"
        />
      </div>
      <div class="FilterTitle">性别</div>
      <div class="FilterList">
        <div
          v-for="item in genderList"
          :key="item"
          class="FilterItem"
          :class="select.gender.indexOf(item) > -1 ? 'FilterActive' : ''"
          :active="select.gender.indexOf(item) > -1"
          @Click="TypeSelect(item, 'gender', 'mult')"
        >
          {{ item }}
        </div>
      </div>
      <div class="FilterTitle" v-if="partner_code == '2300'">适应年龄</div>
      <div class="FilterList" v-if="partner_code == '2300'">
        <div
          v-for="item in ageList"
          :key="item"
          class="FilterItem"
          :class="select.age.indexOf(item) > -1 ? 'FilterActive' : ''"
          :active="select.age.indexOf(item) > -1"
          @Click="ageSelect(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="FilterTitle">年份</div>
      <div class="FilterList">
        <div
          v-for="item in YearsList"
          :key="item"
          class="FilterItem"
          :class="select.year == item ? 'FilterActive' : ''"
          :active="select.year == item.id"
          @Click="TypeSelect(item, 'year')"
        >
          {{ item }}
        </div>
      </div>
      <div class="FilterTitle">季节</div>
      <div class="FilterList">
        <div
          v-for="item in SeasonsList"
          :key="item"
          class="FilterItem"
          :class="select.season.indexOf(item) > -1 ? 'FilterActive' : ''"
          :active="select.season.indexOf(item) > -1"
          @Click="TypeSelect(item, 'season', 'mult')"
        >
          {{ item }}
        </div>
      </div>
      <div class="FilterTitle">价格区间</div>
      <div class="SelectList">
        <Input
          class="MinPrice"
          v-model="select.price_start"
          @blur="Checkprice('start')"
          :formatter="changePriceRange"
          type="number"
          placeholder="最低价"
        />
        <div class="FilterSplit">-</div>
        <Input
          class="MaxPrice"
          v-model="select.price_end"
          @blur="Checkprice('end')"
          :formatter="changePriceRange"
          type="number"
          placeholder="最高价"
        />
      </div>
      <div class="FilterTitle" v-if="is_business !== 1">库存区间</div>
      <div class="SelectList" v-if="is_business !== 1">
        <Input
          class="MinStock"
          v-model="select.stock_start"
          @blur="Checkstock('start')"
          :formatter="changeStockRange"
          type="number"
          placeholder="最低库存"
        />
        <div class="FilterSplit">-</div>
        <Input
          class="MaxStock"
          type="number"
          @blur="Checkstock('end')"
          :formatter="changeStockRange"
          v-model="select.stock_end"
          placeholder="最高库存"
        />
      </div>
      <div class="FilterTitle" v-if="products_business_type == 1">
        是否贴牌商品
      </div>
      <RadioGroup
        class="seeClose"
        v-model="select.is_oem"
        direction="horizontal"
        v-if="products_business_type == 1"
      >
        <Radio name="0" icon-size="16px">否</Radio>
        <Radio name="1" icon-size="16px">是</Radio>
      </RadioGroup>

      <div class="ButtonList van-hairline--top">
        <Button size="small" id="ResetBtn" @click="resetParams">重置</Button>
        <Button
          size="small"
          id="NotarizeBtn"
          @click="filterCheck"
          v-log="{ describe: { action: '点击筛选' } }"
          >确定</Button
        >
      </div>
    </Popup>
  </div>
</template>

<script>
import { Popup, Toast, Badge, RadioGroup, Radio } from 'vant'
import * as api from '../../api'
import { user, device } from '@/store'
import Button from '../../components/Button'
import Search from '../../components/Search'
import SortImg from '../../assets/image/icon_paixu.png'
import updateImg from '../../assets/image/icon_paixu_shang.png'
import downImg from '../../assets/image/icon_paixu_xia.png'
import MyNavbar from '../../components/MyNavbar'
import MyHeader from '../../components/MyHeader'
import Image from '../../components/Image'
import List from '../../components/List'
import Input from '../../components/Input'
import NoticeBar from '@/components/NoticeBar'
import Icon from '@/components/Icon'
import * as chat from '@/utils/chat'
import GoodsCard from './components/GoodsCard.vue'

export default {
  name: 'Goods',
  data() {
    return {
      params: {
        keywords: '',
        top_class_code: '',
        gender: [],
        season: [],
        bottom_class: [],
        year: '',
        price_start: '',
        price_end: '',
        stock_start: '',
        stock_end: '',
        stock_order: '',
        is_oem: '',
        sale_order: '',
        bottom_class_code: '',
        is_new: '',
        age: [],
      },
      select: {
        keywords: '',
        top_class_code: '',
        gender: [],
        year: '',
        season: [],
        bottom_class: [],
        price_start: '',
        price_end: '',
        stock_start: '',
        stock_end: '',
        stock_order: '',
        sale_order: '',
        is_oem: '',
        bottom_class_code: '',
        is_new: '',
        age: [],
      },
      UnreadMsgCount: 0,
      sortImg: SortImg,
      current: 1,
      size: 6,
      GoodsList: [],
      state: {
        loading: true,
        finished: false,
        uploading: true,
      },
      title: '商品',
      genderList: [],
      SeasonsList: [],
      Filter: false,
      FilterTypeList: [],
      YearsList: [],
      ageList: [],
      FilterType: 0,
      FilterIn: false,
      GoodListShow: true,
      LoadingShow: false,
      canClosePopup: false,
      error: false,
      sortType: 'all',
      isWeixinLook: device.isWeixinLook,
      has_special: false,
      has_pre_sales: false,
      autofocus: false,
      searchKey: 0, // keepAlive，通过 key 强制刷新DOM
      focusNum: 0,
      partner_code: user.userData.partner_code,
      products_business_type: user.userData.products_business_type,
      is_business: user.userData.is_business,
    }
  },
  components: {
    List,
    Button,
    Search,
    MyNavbar,
    Popup,
    MyHeader,
    Image,
    Input,
    Toast,
    Badge,
    NoticeBar,
    Icon,
    GoodsCard,
    RadioGroup,
    Radio,
  },
  watch: {
    params: {
      handler(newValue) {
        if (newValue.sale_order > 0) {
          this.sortType = 'sales'
        }
        if (newValue.stock_order > 0) {
          this.sortType = 'stock'
          if (newValue.stock_order == 2) {
            this.sortImg = downImg
          } else if (newValue.stock_order == 1) {
            this.sortImg = updateImg
          }
        }
        if (newValue.is_new > 0) {
          this.sortType = 'news'
        }
        if (
          newValue.gender?.length +
            newValue.season?.length +
            newValue.bottom_class?.length +
            newValue.price_start?.length +
            newValue.price_end?.length +
            newValue.stock_start?.length +
            newValue.stock_end?.length +
            newValue.top_class_code?.length +
            newValue.year?.length +
            newValue.age?.length >
          0
        ) {
          this.FilterIn = true
        } else {
          this.FilterIn = false
        }
      },
      deep: true,
    },
    $route(to, form) {
      //本页面 修改URL 重新获取数据
      if (to.name === form.name) {
        this.getRoute()
      }
      // if (to.path === '/Goods' && form.path !== '/GoodsDetail') {
      //   setTimeout(() => {
      //     this.getGoodsList()
      //   })
      // }
    },
  },
  created() {
    let that = this
    if (this.$route.query.autofocus && this.focusNum == 0) {
      this.autofocus = true
      this.focusNum++
    } else {
      this.autofocus = false
    }
    // if (that.GoodsList.length == 0) {
    //   that.getGoodsList()
    // }
    if (that.FilterTypeList.length == 0) {
      that.getTopClass()
    }
  },
  activated() {
    this.searchKey += 1
    chat.getUnreadMsgCount().then((o) => {
      this.UnreadMsgCount = o
    })
    if (this.$route.query.autofocus) {
      for (let i in this.params) {
        if (this.params[i] !== '' && !this.autofocus) {
          this.getRoute()
          break
        }
      }
      this.autofocus = this.$route.query.autofocus
    } else {
      this.autofocus = false
    }
    if (
      !this.$route.query.autofocus &&
      Object.keys(this.$route.query).length > 0
    ) {
      if (!this.$route.meta.isBack) {
        this.getRoute()
        this.autofocus = false
      }
    } else if (this.GoodsList.length == 0) {
      this.getGoodsList()
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == 'GoodsDetail') {
      // 这个name是下一级页面的路由name
      to.meta.isBack = true // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    }
    next()
  },
  methods: {
    openChat: chat.openChat,
    toDetail(good) {
      this.$router.push({
        name: 'GoodsDetail',
        query: {
          id: good.id,
          keywords: this.params.keywords,
        },
      })
    },
    SortList(type) {
      let that = this
      that.current = 1
      that.sortType = type
      switch (type) {
        case 'all':
          that.params.stock_order = ''
          that.params.is_new = ''
          that.params.sale_order = ''
          break
        case 'sales':
          that.params.stock_order = ''
          that.params.is_new = ''
          if (that.params.sale_order !== 2) {
            that.params.sale_order = 2
          }
          break
        case 'stock':
          if (that.params.stock_order == '') {
            that.sortImg = downImg
            that.params.stock_order = 2
          } else if (that.params.stock_order == 1) {
            that.sortImg = downImg
            that.params.stock_order = 2
          } else if (that.params.stock_order == 2) {
            that.sortImg = updateImg
            that.params.stock_order = 1
          }
          that.params.sale_order = ''
          that.params.is_new = ''
          break
        case 'news':
          that.params.stock_order = ''
          that.params.sale_order = ''
          if (that.params.is_new !== 1) {
            that.params.is_new = 1
          }
          break
        default:
          that.params.stock_order = ''
          that.params.sale_order = ''
          that.params.is_new = ''
          break
      }
      that.changRoute()
    },
    getRoute() {
      this.current = 1
      this.sortType = 'all'
      this.GoodsList = []
      this.params = {
        keywords: '',
        top_class_code: '',
        gender: [],
        season: [],
        bottom_class: [],
        year: '',
        is_oem: '',
        price_start: '',
        price_end: '',
        stock_start: '',
        stock_end: '',
        stock_order: '',
        sale_order: '',
        bottom_class_code: '',
        is_new: '',
        age: [],
        goods_son_class: [],
      }
      Object.keys(this.params).forEach((key) => {
        if (
          this.$route.query[key] &&
          this.$route.query[key].indexOf(',') >= 0
        ) {
          this.params[key] = this.$route.query[key].split(',')
        } else if (
          this.$route.query[key] &&
          (key == 'gender' ||
            key == 'bottom_class' ||
            key == 'age' ||
            key == 'season'||
            key == 'goods_son_class')
        ) {
          this.params[key] = Array.isArray(this.$route.query[key])
            ? this.$route.query[key]
            : [this.$route.query[key]]
        } else if (this.$route.query[key]) {
          this.params[key] = this.$route.query[key]
        }
      })
      this.select = JSON.parse(JSON.stringify(this.params))
      this.getGoodsList()
    },
    changRoute() {
      let that = this
      this.$router.replace({
        query: {
          keywords: that.params.keywords,
          top_class_code: that.params.top_class_code,
          gender: that.params.gender,
          year: that.params.year,
          season: that.params.season,
          bottom_class: that.params.bottom_class,
          price_start: that.params.price_start,
          price_end: that.params.price_end,
          stock_start: that.params.stock_start,
          stock_end: that.params.stock_end,
          stock_order: that.params.stock_order,
          is_oem: that.params.is_oem,
          sale_order: that.params.sale_order,
          bottom_class_code: that.params.bottom_class_code,
          is_new: that.params.is_new,
          age: that.params.age,
          goods_son_class:that.params.goods_son_class
        },
      })
    },
    changePriceRange(value) {
      value = value.replace(/[^\d.]/g, '') //清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.') //只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
      if (value.indexOf('.') == 0) {
        value = value.slice(1, value.length)
      }
      if (value.indexOf('.') > 0) {
        value = value.slice(0, 8)
      } else {
        value = value.slice(0, 5)
      }
      if (value.indexOf('.') < 0 && value != '') {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value)
      }
      return value
    },
    changeStockRange(value) {
      value = value.replace(/[^\d]/g, '') //清除“数字”和“.”以外的字符
      value = value.replace(/[^0-9-]+/, '')
      if (value.indexOf('0') == 0) {
        value = value.slice(1, value.length)
      }
      return value
    },
    setFilter() {
      let that = this
      that.canClosePopup = true
      that.Filter = !that.Filter
      that.params.keywords = that.select.keywords
      that.select = JSON.parse(JSON.stringify(that.params))
    },
    closeOverLay() {
      let that = this
      that.canClosePopup = true
    },
    closePopup() {
      let that = this
      if (that.canClosePopup !== true) {
        that.Filter = true
      }
    },
    openPopup() {
      let that = this
      that.canClosePopup = false
    },
    async getGoodsList() {
      let that = this
      that.state.loading = true
      that.error = false
      if (that.current == 1) {
        that.GoodsList = []
        that.LoadingShow = true
      }
      that.Filter = false
      const params = { ...that.params, current: that.current, size: that.size }
      const { data, error } = await api.goods.getGoodsList(params, false)
      that.state.loading = false
      api.errorProcessor({ error, throwError: false })
      that.LoadingShow = false
      if (error) {
        that.error = true
      }
      if (data) {
        that.has_special = data.metal.has_special
        that.has_pre_sales = data.metal.has_pre_sales
        if (data.list.length === 0) {
          that.state.finished = true
        }
        if (data.list.length === 6) {
          that.state.finished = false
        }
        if (data.page.current > 1) {
          that.GoodsList = that.GoodsList.concat(data.list)
        } else {
          window.scrollTo(0, 0)
          that.GoodsList = data.list
          console.log(that.GoodsList)
          if (that.GoodsList.length == 0) {
            that.GoodListShow = false
          } else {
            that.GoodListShow = true
          }
        }
        that.current++
      }
    },
    TypeSelect(id, name, type) {
      let that = this
      if (type == 'mult') {
        if (that.select[name].indexOf(id) > -1) {
          that.select[name].splice(that.select[name].indexOf(id), 1)
        } else {
          that.select[name].push(id)
        }
      } else {
        if (that.select[name] == id) {
          that.select[name] = ''
        } else {
          that.select[name] = id
        }
      }
    },
    ageSelect(data) {
      let that = this
      if (that.select.age.indexOf(data) > -1) {
        that.select.age.splice(that.select.age.indexOf(data), 1)
      } else {
        that.select.age.push(data)
      }
    },
    async getTopClass() {
      let that = this
      const { data, error } = await api.goods.getTopClass()
      api.errorProcessor({ error })
      that.FilterTypeList = data.bottom_class
      that.genderList = data.gender
      that.SeasonsList = data.season
      that.YearsList = data.year
      that.ageList = data.age
    },
    selectGoodsList() {
      let that = this
      that.select = {
        keywords: that.select.keywords,
        top_class_code: '',
        gender: [],
        year: '',
        age: [],
        bottom_class: [],
        season: [],
        price_start: '',
        price_end: '',
        stock_start: '',
        is_oem: '',
        stock_end: '',
        stock_order: '',
        sale_order: '',
        goods_son_class: [],
        bottom_class_code: '',
        is_new: '',
      }
      that.sortType = 'all'
      that.params = JSON.parse(JSON.stringify(that.select))
      that.stockImg = SortImg
      that.yearImg = SortImg
      that.current = 1
      that.changRoute()
    },
    filterCheck() {
      let that = this
      if (Number(that.select.stock_end) > 0) {
        if (Number(that.select.stock_start) > Number(that.select.stock_end)) {
          that.select.stock_end = ''
          Toast('区间范围错误，请重新输入')
          return false
        }
      }
      if (Number(that.select.price_end) > 0) {
        if (Number(that.select.price_start) > Number(that.select.price_end)) {
          that.select.price_end = ''
          Toast('区间范围错误，请重新输入')
          return false
        }
      }
      that.canClosePopup = true
      that.select.bottom_class_code = ''
      that.params = JSON.parse(JSON.stringify(that.select))
      that.current = 1
      that.Filter = false
      that.changRoute()
    },
    resetParams() {
      let that = this
      this.select = {
        keywords: that.params.keywords,
        top_class_code: '',
        gender: [],
        age: [],
        year: '',
        season: [],
        bottom_class: [],
        price_start: '',
        price_end: '',
        is_oem: '',
        stock_start: '',
        stock_end: '',
        stock_order: '',
        bottom_class_code: '',
        is_new: '',
      }
    },
    openClassify() {
      this.FilterTypeList.Show = !this.FilterTypeList.Show
    },
    sendDist() {
      chat.openChatDistribution(this.params.keywords)
    },
    Checkstock(type) {
      let that = this
      if (Number(that.select.stock_end) > 0) {
        if (Number(that.select.stock_start) > Number(that.select.stock_end)) {
          if (type == 'start') {
            that.select.stock_start = ''
            Toast('区间范围错误，请重新输入')
          } else {
            that.select.stock_end = ''
            Toast('区间范围错误，请重新输入')
          }
        }
      }
    },
    Checkprice(type) {
      let that = this
      if (Number(that.select.price_end) > 0) {
        if (Number(that.select.price_start) > Number(that.select.price_end)) {
          if (type == 'start') {
            that.select.price_start = ''
            Toast('区间范围错误，请重新输入')
          } else {
            that.select.price_end = ''
            Toast('区间范围错误，请重新输入')
          }
        }
      }
    },
    closeWeixinTips() {
      device.setIsWeixinLook(false)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../styles/index.less';

@space: rem(5);
.SerchBox {
  padding: rem(14) 0;
  display: flex;
  background: #ffffff;
}
.Goodstab {
  margin-top: rem(10);
  display: flex;
  padding-right: @space * 2;
  a {
    width: calc(~'50% - ' @space * 2);
    margin-left: @space * 2;
    display: block;
  }
  img {
    width: 100%;
    display: block;
  }
}
.TopSearch {
  padding: 0;
  flex: 1;
  margin: 0 rem(11);
  font-size: rem(14);
  border-radius: rem(999);
  :deep(.search-input) {
    padding-right: rem(10);
  }
}
.SelectBtn {
  font-size: rem(14);
  background: @primary-gradient;
  border-radius: rem(30);
  height: calc(~'100% - ' 2px);
  border: 0;
  width: rem(61);
  margin-right: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: rem(8) rem(16);
  color: #ffffff;
}
.ConditionList {
  display: flex;
  background-color: #ffffff;
  font-size: rem(14);
  padding-bottom: rem(12);
  color: #524f4f;
  .ActiveSort {
    color: #f56c6c;
  }
}
.ConditItem {
  flex: 1;
  text-align: center;
}
.SortImg {
  width: rem(5.57);
  height: rem(9.22);
  margin: 0 rem(4);
}
.Filter {
  margin: 0 rem(2.5);
  color: #999999;
}
.ConditSize {
  line-height: rem(20);
}
.ShopList {
  display: flex;
  margin-top: rem(9);
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: @space * 2;
  padding-bottom: rem(49);
  :deep(.van-list__loading) {
    flex-basis: 100%;
  }
  :deep(.van-list__finished-text) {
    flex-basis: 100%;
  }
  :deep(.van-list__error-text) {
    flex-basis: 100%;
  }
}
.ShopItem {
  border-radius: rem(4);
  width: calc(~'50% - ' @space * 2);
  margin-left: @space * 2;
  justify-content: space-around;
  background-color: #ffffff;
  margin-bottom: rem(9);
}
.ShopImgBox {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.presellstatus {
  position: absolute;
  top: rem(5);
  right: rem(3);
}
.ShopImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ShopTitle {
  margin-top: rem(12);
  margin-bottom: rem(8);
  margin-left: rem(10);
  font-size: rem(13);
  text-overflow: ellipsis;
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ShopPrice {
  font-size: rem(15.5);
  color: #f56c6c;
  margin-bottom: rem(8);
  margin-left: rem(10);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ShopStock {
  color: #9c9c9c;
  font-size: rem(11);
  margin-left: rem(10);
  margin-bottom: rem(8);
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Center {
  :deep(.van-popup) {
    width: rem(300);
    height: 100%;
    overflow: scroll;
    background-color: #ffffff;
  }
}
.CloseFilter {
  position: absolute;
  width: rem(13);
  height: rem(13);
  right: rem(13.5);
  top: rem(12);
}
.FilterList {
  display: flex;
  flex-wrap: wrap;
  padding-left: rem(20);
  padding-right: rem(10);
  padding-top: rem(10);
}
.FilterItem {
  font-size: rem(12);
  padding: rem(8) 0;
  width: rem(78);
  border-radius: rem(54);
  text-align: center;
  margin-right: rem(10);
  margin-top: rem(10);
  background: #f2f2f2;
  border: 0.5px solid #f2f2f2;
}
.FilterActive {
  background: #fef0f0;
  border: 0.5px solid #f56c6c;
  color: #f56c6c;
}
.FilterTitle {
  font-weight: bold;
  margin-top: rem(38);
  margin-left: rem(20);
  font-size: rem(13);
}

.SelectList {
  display: flex;
  flex-wrap: nowrap;
  padding-top: rem(20);
  :deep(input) {
    height: rem(30);
    font-size: rem(12);
    text-align: center;
    background: #f2f2f2;
    border: 0;
    border-radius: rem(999);
  }
  :deep(.van-cell) {
    padding: 0;
  }
  .MinPrice {
    margin-left: rem(20);
  }
  .MaxPrice {
    margin-right: rem(20);
  }
  .MinStock {
    margin-left: rem(20);
  }
  .MaxStock {
    margin-right: rem(20);
  }
}
.FilterSplit {
  flex: 1;
  margin: rem(11);
  font-size: rem(12);
}
.ButtonList {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: rem(8) rem(10);
  padding-bottom: 0;
  margin-top: rem(37.5);
  height: rem(42);
  display: flex;
  .safe-area-inset-bottom();
}
#ResetBtn {
  font-size: rem(14);
  width: rem(136);
  border: 1px solid rgba(246, 108, 108, 0.5);
  background: #ffffff;
  border-radius: rem(450);
  color: #f56c6c;
}
#NotarizeBtn {
  margin-left: rem(8);
  font-size: rem(14);
  border: 0;
  width: rem(136);
  background: linear-gradient(270.27deg, #fa5d38 0.46%, #f56c6c 97.62%);
  color: #ffffff;
  border-radius: rem(450);
}
.van-list__loading,
.van-list__finished-text,
.van-list__error-text {
  width: 100%;
}
.TopBox {
  background: #ffffff;
}
.MoneyIcon {
  font-size: rem(11);
}
.OpenClassify {
  margin-top: rem(20);
  display: flex;
  justify-content: center;
  img {
    width: rem(50);
  }
}
.NotFoundBox {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: rem(115);
}
.NotFoundImg {
  width: rem(160.19);
  height: rem(160.19);
}
.NotFoundFontTitle {
  font-size: rem(17);
  font-weight: 600;
  color: #333333;
  text-align: center;
}
.NotFoundFont {
  font-size: rem(15);
  text-align: center;
  margin-top: rem(8);
  color: #9c9ca5;
}

.LoadingBox {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  z-index: 1100;
}

.spinner {
  background: rgba(51, 51, 51, 0.6);
  border-radius: rem(999);
  width: rem(91);
  text-align: center;
  font-size: 0;
  padding: rem(19) 0;
}

.spinner > div {
  width: rem(10);
  height: rem(10);
  background-color: #fff;
  margin-right: rem(5);
  margin-left: rem(5);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.special {
  padding: rem(10);
  padding-bottom: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  a {
    width: 100%;
    display: block;
  }
}
.service {
  margin: 0 rem(18) 0 0;
  color: #f56c6c;
  font-size: rem(22);
}
.distribution {
  padding: rem(10) rem(12);
  color: #ffffff;
  font-size: rem(14);
  background: linear-gradient(270deg, #fa5d38, #f56c6c 98%);
  border-radius: rem(450);
  margin-top: rem(18);
}
.seeClose {
  margin-left: rem(20);
  margin-top: rem(10);
  font-size: rem(13);
}
</style>
